//import scene from "../assets/vs-app.json";
import { ISceneNode } from "@mp/common";

export class SceneLoader {
  public nodes: ISceneNode[] = [];

  constructor(private sdk: any) {}

  /**
   * Load the scene for a given model.
   *
   * @param sid sid of the model, used to lookup the scene.
   * @param callback an optional callback which is called once for scene node created.
   */
  
  // 로드 함수 
  public async load(sid: string, callback: (node: ISceneNode) => void = null) {
      
    const nodesToStop = this.nodes.splice(0);
    for (const node of nodesToStop) {
      node.stop();
    }
    //var videoDelayNumber = 0;
    var videoDelayNumber = 500;
     var url;
  //  if (window.location.href.indexOf("localhost")) {
  //    console.log("로컬 실행입니다.");
  //    url = new URL(
  //      "http://3.36.235.113/buyeo/?m=NbA9Vo2qdMV&ss=3&sr=0,3.15&qs=1&play=1&hr=1"
  //    ); // 로컬 테스트용 url 설정
  //  } else {
  //    console.log("로컬 실행이 아닙니다.");
  //    //const urltmp = window.location.href;

  //    const urltmp = window.location.href;
  //    //=> location.href 로 바꿔야할듯

  //    url = new URL(urltmp);
  //  }
   
    var url;
    
    const urltmp = window.location.href
    
    url = new URL(urltmp);

     const userAgent = navigator.userAgent.toLowerCase(); // User Agent 문자열을 소문자로 변환하여 저장
     if (userAgent.indexOf("mobile") !== -1) {
       //console.log("모바일 디바이스입니다.");
       videoDelayNumber = 1750;
       //isMap = false;
       if (
         userAgent.indexOf("iphone") !== -1 ||
         userAgent.indexOf("ipad") !== -1
       ) {
        // console.log("iOS 디바이스입니다.");
         //isMap = false;

         // iOS 디바이스 처리 로직 작성
       } else if (userAgent.indexOf("android") !== -1) {
        // console.log("안드로이드 디바이스입니다.");
         //isMap = false;
         // 안드로이드 디바이스 처리 로직 작성
       } else {
        // console.log("모바일 디바이스이지만, iOS나 안드로이드가 아닙니다.");
         //isMap = false;
         // 기타 모바일 디바이스 처리 로직 작성
       }
     } else if (userAgent.indexOf("tablet") !== -1) {
       //isMap = true;
       //console.log("태블릿 디바이스입니다.");

       // 태블릿 디바이스 처리 로직 작성
     } else if (
       userAgent.indexOf("macintosh") !== -1 ||
       userAgent.indexOf("mac os x") !== -1
     ) {
       //isMap = true;
       //console.log("맥북입니다."); // 맥북 디바이스 처리 로직 작성
     } else {
       //isMap = true;
       //console.log("데스크톱 디바이스입니다.");
       // 데스크톱 디바이스 처리 로직 작성
     }
    

    // URLSearchParams 객체
    const urlParams = url.searchParams;
    //console.log("씬로더 urlParams.get(m) : "+urlParams.get("m"));
    
    const sceneImport = await import(
      "../assets/" + urlParams.get("m") + ".json"
    );

    //console.log("sceneLoader : " + urlParams.get("device"));

    //console.log("../assets/" + urlParams.get("m") + ".json");

    const sidToScene: Map<string, any> = new Map();

    sidToScene.set("AAWs9eZ9ip6", sceneImport);

    let scene = sidToScene.get(sid);

    if (!scene) {
      return;
    }

    const nodesToStart: ISceneNode[] = await this.sdk.Scene.deserialize(
      JSON.stringify(scene)
    );// json 파일을 파쇄
      
    if (callback) {
      for (const node of nodesToStart) {
       
        callback(node);
      }
    }
  
    const lights = await this.sdk.Scene.createNode();
    var initial = {
      enabled: false,
      color: {
        r: 0,
        g: 1,
        b: 0,
      },
      intensity: 100.8,
      position: {
        x: -7.01,
        y: 1.51,
        z: 16.5,
      },
      target: {
        x: 0,
        y: 0,
        z: 0,
      },
      debug: true,
    };

    lights.addComponent("mp.ambientLight", initial);
    
    lights.start();
    
    for (const node of nodesToStart) { // 입력
      
      await new Promise((resolve) => setTimeout(resolve, videoDelayNumber));
      
    
      node.start(); // 시작
      
      this.nodes.push(node); // 목록에 추가 
    }
    

  
    //
    
    //console.log(this.nodes[0])
    
    //배열로 전체 node 배열을 등록하고,  각 배열에 spyonevent를 통해 이벤트가 발생하는 것을 감지하고,
    // 이벤가 발생한 배열을 제외하고 나머지에  다시 이벤트를 발생시켜서, 음소거 상태로 전환


    //sceneloader에서  
    //return node;
  }

  
  
  

  public *nodeIterator(): IterableIterator<ISceneNode> {
    for (const node of this.nodes) {
      //console.log("nodeIterator");
      //console.log("node : ",node)
      yield node;
    }
  }
}

// const scenePath = "../assets/vs-app.json";
// import(scenePath).then((module)=> {
//   module.default();
//   module.객체();
// })

// const sidToScene: Map<string, any> = new Map();

// sidToScene.set("AAWs9eZ9ip6", scenePath);
